.kserv-grid-cell-button {
  margin-left: auto;
  margin-right: auto;
  display: block;
}

.kserv-accept-cell {

}

.kserv-checkbox-cell {

}

.kserv-details-cell {

}

.kserv-edit-cell {

}

.kserv-reject-cell {

}

.kserv-pdf-button {
  color: red;
}

.kserv-excel-button {
  color: green;
}