.kserv-project-column-log-page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: auto;
  align-items: center;
}

.k-dialog-wrapper.kserv-download-dialog {
  z-index: 10015;
}

.kserv-project-column-log-grid {
  width: 95%;
  min-width: auto;
  margin: auto;
  alignment: center;
  font-size: 10px;
}

.kserv-range-statistics {
  display: flex;
  flex-direction: row;
  justify-content: center;

  .range-statistics-entry {
    display: flex;
    flex-direction: column;
    //width: 160px;
    margin: 5px;
    text-align: center;
  }

  .w-s {
    width: 80px;
  }

  .w-m {
    width: 120px;
  }

  .w-l {
    width: 160px;
  }

  .range-statistics-title {
    font-size: small;
  }

  .range-statistics-text {
    font-size: x-large;
  }
}
