.kserv-drawing-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: stretch;
  margin-bottom: 0.5rem;
}


.kserv-drawing-map-details {
  .k-card-body {
    .k-card-title {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
    }
  }

  background: #f3f3f4;
}

.kserv-drawing-map-details-holder {
  display: flex;
  width: 100%;
  overflow: hidden;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
  flex-shrink: 1;
}

.kserv-column-details-card-header {
  display: flex;

  span:first-child {
    visibility: hidden;
    margin-right: auto;
  }

  :last-child {
    margin-left: auto;
  }

}

.span-first-child-unhack {
  span:first-child {
    visibility: visible;
  }
}

.kserv-filter-box-comp-width {
  width: 140px !important;
}

.kserv-drawing-map {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
  flex-grow: 1;
}


.kserv-drawing-map-div {
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 0;
}

.kserv-map-select:hover {
    cursor: pointer;
}

.kserv-map-drag {
    cursor: move;
}

.kserv-drawing-map-svg {
  box-sizing: border-box;
  width: 100%;
  height: 65vh;
  border: 1px solid black;
  background-color: #777;
  /*
   * Flip y-axis.
   * https://stackoverflow.com/a/38813657/4435991
   */
  transform-origin: 50% 50%;
  transform: scale(1, -1);
}

.kserv-drawing-map-columns {
  circle {
    stroke: black;
    stroke-width: 1px;
    vector-effect: non-scaling-stroke;
  }
  text {
    vector-effect: non-scaling-stroke;
    font-size: 12px;
    text-anchor: middle;
    dominant-baseline: middle;
    pointer-events: none;
    transform: scaleY(-1);
  }
}

div.tooltip-area {
  position: absolute;
  text-align: center;
  z-index: 10009;
  padding: .5rem;
  background: #FFFFFF;
  color: #313639;
  border: 1px solid #313639;
  border-radius: 8px;
  pointer-events: none;
  font-size: 1.3rem;
}

.kserv-drawing-map-obstacles {
  line {
    stroke-width: 1px;
    opacity: 0.9;
  }
  circle {
    fill: transparent;
    /*
    https://stackoverflow.com/questions/12905808/how-to-make-an-svg-text-element-click-through-able
     */
    pointer-events: visibleStroke;
    stroke-width: 1px;
    opacity: 0.9;
  }
  // Default color if invalid color is supplied
  stroke: blue;
}

.kserv-drawing-column-selected {
  fill: blueviolet;
}

.kserv-drawing-column-available {
  fill: white;
}

.kserv-drawing-column-completed {
  fill: green;
}

.kserv-drawing-column-failed {
  fill: red;
}

.kserv-drawing-column-aborted {
  fill: darkorange;
}

.kserv-drawing-plot-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.kserv-drawing-plot-header-buttons {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  button {
    margin-top: 0.2rem;
    margin-left: 0.5rem;
    width: 65px;
  }
}


.kserv-map-filter-button-holder {
  display: flex;
  justify-content: flex-start;
  padding-top: 1.2rem;
}

.kserv-map-filter-button {
  display: block;
  margin-right: 1em;
}

.kserv-hidden {
  visibility: hidden;
}

.kserv-invert {
  filter: invert(100);
}


// https://stackoverflow.com/a/4407335
.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome, Opera and Firefox */
}