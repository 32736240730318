.kserv-dashboard {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  //margin: auto;
  align-items: stretch;
}


.project-dashboard-card-title {
  font-size: small;
}

.project-dashboard-card-text {
  font-size: x-large;
}

.kserv-dashboard-group-card {
  border: none;
  .kserv-dashboard-group-card-body{
    padding: 0;
  }
}

.kserv-dashboard-card {
  background: #f3f3f4;
  padding: 0.5em;
  margin-bottom: 0.5em;
  margin-right: 0.5em;
}

.kserv-dashboard-card.k-card {
  border-radius: 4px;
}

.kserv-column-count-chart {
  width: 400px;
}

.kserv-total-depth-chart {
  width: 300px;
}

.kserv-drill-per-week-chart {
  width: 400px;
}