.kserv-chiplist-div {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.kserv-chiplist {
  .k-chip-list {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    flex-wrap: wrap;
  }

  .k-chip-list:focus {
    outline: none;
  }
  max-width: 100%;
  padding-bottom: 5px;
  border-top: #dddddf 1px solid;
  border-bottom: #dddddf 1px solid;
  background: #ffffff;
}

.kserv-chiplist-toolbar {
  background-color: #f1f1f3;
  padding-left: 5px;
  padding-top: 5px;
  padding-bottom: 5px;
  button {
    margin-right: 5px;
  }
}