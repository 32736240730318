.kserv-machine-grid {
  width: 800px;
  min-width: auto;
  margin: auto;
  alignment: center;
}

.kserv-machine-details-item {
  margin-bottom: 6px;
  width: 600px
}