.kserv-tab-content{
  width: 100%;
  justify-content: center;
  align-content: center;
  justify-items: center;
  align-items: center;
  margin: auto;
  display: flex;
}

.kserv-drawing-upload-page {
  width: 80%;
  justify-content: center;
  align-content: center;
  justify-items: center;
  align-items: center;
  margin: auto;
  display: flex;
}

.kserv-upload-title {
  font-size: medium;
  font-weight: bold;

}

.kserv-drawing-checkbox {
  width: 100%;
  justify-content: center;
  align-content: center;
  justify-items: center;
  align-items: center;
  display: flex;
  border-bottom: 1px solid #515967;
  padding-bottom: 10px;
}

.kserv-project-drawing-history {
  min-width: 600px;
  max-width: 800px;
  margin: 5px;
  height: 200px;
}

.kserv-project-drawing-difference {
  margin: 5px;
  max-height: 600px;
}