
.kserv-page-title {
  display: flex;
  width: 100%;
  padding-left: 25px;
  margin-right: auto;
  border-bottom: 1px solid #eaeaea;
}

.kserv-page-title-text {
  display: block;
  color: #4f5966;
  font-size: 1.17em;
  margin: 0.5em 0 0.5em;
  font-weight: bold;

  .kserv-button {
    display: inline-flex;
    margin-left: 10px;
  }
}