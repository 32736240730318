.kserv-edit-graph-header-row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
}

.kserv-edit-graph-switch{
  margin-right: 5px;
  margin-left: 10px;
}

.kserv-edit-graph-dropdown{
  margin-left: 10px;
  width:240px;
}

.kserv-column-graph-svg {
  box-sizing: border-box;
  width: 100%;
  height: 40vh;
  border: 1px solid #dedede;
  background-color: #f3f3f4;
  margin-top: 10px;
  /*
   * Flip y-axis.
   * https://stackoverflow.com/a/38813657/4435991
   */
  //transform-origin: 50% 50%;
  //transform: scale(1, -1);


  overflow-x: hidden;
  overflow-y: auto;
  //text-align:justify;

  //overflow: auto;
  //text-align:justify;
}


.kserv-column-edit-graph-buttons {
  margin-left: 10px;
  display: flex;
  flex: 1 0;
  justify-items: stretch;
  flex-wrap: wrap;
  Button {
    border-radius: 40px !important;
    margin-right: 4px;

    :last-child {
      margin-right: 0;
    }
  }
  .k-state-active {
    background-color: #003768;
    color: #ffffff;
  }
}

.kserv-column-edit-control {
  display: flex;
  flex-flow: row;
  justify-content: flex-end;
  margin-top: 5px;
  .kserv-dropdown-filter-list {
    width:280px;
    margin-right: 10px;
  }
  .kserv-dropdown-filter-button {
    margin-right: 10px;
  }
  :last-child {
    margin-right: 0;
  }
}

.modebar-container {
  position: sticky !important;
}

.modebar {
  position: sticky !important;
  right: 2px !important;
}

div.select-tooptip {
  position: absolute;
  z-index: 10011;
}

.kserv-pdf-toolbar {
  .k-button {
    margin: 2px;
  }

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-content: flex-start;
}