body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100%;
  height: 100vh;
  overflow: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.kserv-app {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.kserv-page-root {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.kserv-page-body {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
}

.kserv-content {
  margin-top: 5px;
  margin-left: 16px;
  margin-right: 16px;
  padding-left: 16px;

  display: flex;
  flex-wrap: wrap;
}

.kserv-loading-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.kserv-card-title {
  font-weight: bold;
  text-align: center;
  padding-bottom: 5px;
  border-bottom: 1px solid #515967;
}

.kserv-link {
  background-color: transparent;
  border: none;
  cursor: pointer;
  text-decoration: underline;
  display: inline;
  margin: 0;
  padding: 0;
}

.kserv-link:hover,
.kserv-link:focus {
  text-decoration: none;
}