.kserv-dashboard-select-button {
  padding-left: 32px;
  padding-bottom: 5px;
  display: flex;
}

.kreg-machine-type-label {
  margin-left: 5px;
  padding: 5px;
  background-color: darkgreen;
  color: white;
}

.kdrain-machine-type-label {
  margin-left: 5px;
  padding: 5px;
  background-color: darkgoldenrod;
  color: white;
}