.kserv-card-entry {
  margin-right: 0.5rem;
  padding-top: 0.5rem;
}

.kserv-project-card {
  width: auto;
  background: #f3f3f4;
}

.kserv-project-settings-actions {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  padding: 1em;
  .k-button {
    margin-left: 0.5rem;
  }
}

.kserv-project-settings-input {
  min-width: 200px;
}

.kserv-project-settings-legend {
  font-size: larger;
  font-weight: bold;
}

.kserv-project-details {
  margin-top: 6px;

  .k-tabstrip-items {
    justify-content: center;
  }
}