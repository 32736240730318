.kserv-dialog-children {
  display: flex;
  flex-direction: column;
}

.kserv-dialog-container {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: flex-start;
  overflow-y: auto;
  width: 100%;
  height: 100%;

  fieldset:not(:first-child) {
    margin-top: 0;
  }

  fieldset:not(:last-child) {
    margin-right: 2rem;
  }

  .kserv-dialog-item:not(:last-child) {
    margin-bottom: 1rem;
  }
}

.kserv-dialog-field {
  display: flex;
  flex-direction: column;
  margin-right: 3em;
  flex-grow: 0;
  flex-shrink: 1;
}

.kserv-dialog-legend {
  font-size: larger;
  font-weight: bold;
}