.kserv-card-entry {
  margin-right: 0.5rem;
  padding-top: 0.5rem;
}

.kserv-project-card {
  width: auto;
  background: #f3f3f4;
}

.kserv-new-project-save {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  padding: 1em;
  .k-button {
    margin-left: 0.5rem;
  }
}

.kserv-project-settings-input {
  min-width: 200px;
}

.kserv-project-settings-legend {
  font-size: larger;
  font-weight: bold;
}

.kserv-project-settings-details {
  display: flex;
  flex-wrap: wrap;
}

.kserv-permissions-grid {
  width: 300px;
  min-width: auto;
  max-height: 400px;
  margin: auto;
  alignment: center;
  td.kserv-project-role-td {
    padding: 0;
  }
}

.kserv-project-role-cell {
  width: 100%;
  padding: 1px;
  //margin-top: 3px;
}

.kserv-fabric-grid {
  width: 300px;
  min-width: auto;
  max-height: 340px;
  margin: auto;
  alignment: center;

  td.not-in-db {
    background-color: orange;
  }
}

.kserv-fabric-ctrl {
  display: flex;
  align-items: center;
  justify-content: space-around;

  button {
    padding: 2px;
    width: 20px;
    text-align: center;
    margin-left: 10px;
  }
}



.kserv-new-fabric {
  //height: 50px;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  button {
    height: 30px
  }
}