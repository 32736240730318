

.k-notification-wrap {
  .k-icon {
    font-size: x-large;
  }
}

.kserv-error-notification {
  font-size: large;
  margin-right: 10px;
}

.kserv-info-notification {
  font-size: large;
  margin-right: 10px;
}

.kserv-error-notification-details-popup {
  .k-dialog {
    width: 80%;
    .k-header {
      background: #d92800;
    }
  }
}

.kserv-notification-group {
  z-index: 10005;
}

.k-dialog-wrapper.kserv-error-notification-details-popup{
  z-index: 10005;
}


.kserv-notification-link {
  font-size: large;
  background-color: transparent;
  border: none;
  cursor: pointer;
  text-decoration: underline;
  display: inline;
  margin: 0;
  padding: 0;
}

.kserv-notification-link:hover,
.kserv-notification-link:focus {
  text-decoration: none;
}
