.kserv-project-page {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow: auto;
  overflow-x: hidden;
}

.kserv-batch-job-grid {
  width: 1400px;
  min-width: auto;
  height: 400px;
  margin: 20px auto auto;
  alignment: center;
  font-size: 10px;
}

.kserv-project-column-spec-grid {
  width: 900px;
  min-width: auto;
  margin: auto;
  alignment: center;
  font-size: 12px;
}

.kserv-grid-toolbar {
  .k-button {
    margin: 2px;
  }

  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-content: flex-start;
}

.kserv-grid-toolbar-splitter{
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-content: flex-start;
  width: 49.5%;
}

.kserv-category-export-button {
  svg {
    margin-right: 5px;
  }
}

.icon::before {
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
}

.caret::before {
  font-family: "Font Awesome 5 Free"; font-weight: 900; content: "\f0da";
  padding-top: 5px;
  margin-right: 7px;
  margin-left: 7px;
}


.kserv-report-categories-card {
  width: 100%;
  height: auto;
  background-color: #f1f1f3;

  .k-card-header {
    background-color: #003768;
    color: white;
    text-align: center;
    padding-bottom: 5px;
    padding-top: 5px;
  }

  .k-card-body {
    padding: 0 0 0 0;

  }

  .k-card-actions {
    padding: 5px 5px 5px 5px;
  }
}

$hooverColor: inherit;

.kserv-panelbar-item {
  .k-item {
    .k-link.k-header {
      color: inherit;
      background-color: $hooverColor !important;
      border: inherit;
    }
    .k-link.k-header.k-state-selected {
      color: inherit;
      background-color: $hooverColor !important;
      border: inherit;
    }
    .k-link.k-header.k-state-focused {
      color: inherit;
      background-color: $hooverColor !important;
      border: none;
      box-shadow: none;
    }
  }
}

.kserv-panelbar-categories {
  .k-item {
    .k-link.k-header {
      color: inherit;
      background-color: $hooverColor !important;
      border: none;

    }
    .k-link.k-header.k-state-selected {
      color: inherit;
      background-color: $hooverColor !important;
      border: none;

    }
    .k-link.k-state-selected {
      color: inherit;
      background-color: $hooverColor !important;
      border: none;
    }
    .k-link.k-header.k-state-focused {
      color: inherit;
      background-color: $hooverColor !important;
      border: none;
      box-shadow: none;
    }
  }
}

.kserv-grid-toolbar-categories  {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-content: space-between;
}

.kserv-grid-toolbar-checkbox {
  display: flex;
  align-items: center;
}

.kserv-grid-toolbar-column-categories{
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-content: flex-start;
}

.k-chip {
  margin: 2px 5px !important;
}

.k-tabstrip.k-header > ul.k-reset {
  display: flex;
  justify-content: center;
  width: 100%;

}
.k-tabstrip-items  :first-child {
  justify-content: left !important;
}
.k-tabstrip.k-header > ul.k-reset > li {
  flex: 0 1 auto;
}

.k-tabstrip>.k-content.k-state-active {
  display: flex;
  justify-content: center;
}

// Allow multiline column headers
.k-grid  .k-grid-header  .k-header  .k-link {
  height: auto;
}

.k-grid  .k-grid-header  .k-header {
  white-space: normal;
}

// Force tabstrips to expand
.k-content {
  .k-animation-container {
    width: 100%;
  }
}

// Removes filter buttons from grid filters
.k-filtercell-operator {
  display: none
}

/* fixes dropdownlist in dialog issue */
.k-animation-container {
  z-index: 10004
}
.k-dialog-wrapper {
  z-index: 10003
}
