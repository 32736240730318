.kserv-navigation-bar {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  border: solid;
  background-color: #f6f6f6;

  div:last-child {
    margin-left: auto;
  }
}

.kserv-navigation-bar-left {
  float: left;
  align-items: flex-start;
  margin-top: auto;
  margin-bottom: auto;
  margin-left: 5rem;
}

.kserv-navigation-bar-right {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  margin-top: auto;
  margin-bottom: auto;
  margin-right: 0.5em;
}

$hover_font_color: #515967;
$hover_bg_color: #b6bdca;

.k-menu-item:hover {
  color: $hover_font_color;
  background: $hover_bg_color;
}

.k-link:hover {
  color: $hover_font_color;
  background: $hover_bg_color;
}