.kserv-date-range-picker {
  display: flex;
  flex-direction: row;
  margin-top: auto;
  justify-content: flex-end;
  align-items: flex-end;
}

.k-textbox-container:first-child:first-child{
  margin-top: auto;
}

.kserv-date-range-picker-button{
  margin-top: auto !important;
}

.k-form-field {
  display: inline-block;
  flex-direction: column;
}

