.kserv-project-grid {
  width: 1200px;
  min-width: auto;
  margin: auto;
  alignment: center;
}

.kserv-dashboard-layout-col {
  display: flex;
  justify-items: flex-start;
  flex-direction: column;
  margin: 5px;
  span {
    margin-bottom: 2px;
  }
}

.kserv-dashboard-layout-row {
  display: flex;
  justify-items: flex-start;
  flex-wrap: wrap;
  flex-direction: row;
  span {
    margin-right: 5px;
  }
}