.kreg-column-management-edit-dialog {
  display: flex;
  flex-direction: column;

  .k-textbox-container {
    margin-bottom: 5px;
  }

  .kreg-column-management-edit-dialog-water {
    margin-bottom: 5px;
    span:first-child {
      margin-right: 10px;
    }
  }
}

.kreg-column-management-edit-dialog-actions {
  display: flex;
  justify-content: center;
}