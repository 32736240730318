.kserv-total-dashboard {
  display: flex;
  justify-content: center;
}

.kserv-total-dashboard-cards {
  display: flex;
  flex-wrap: wrap;
}

.kserv-total-dashboard-year {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  .kserv-total-dashboard-year-text {
    font-size: large;
    padding-top: 5px;
    margin-right: 5px;
  }
  .k-button {
    margin-left: 1.5rem;
  }
}

.kserv-total-dashboard-machine-title {
  font-size: 20px;
  text-align: left;
  margin-bottom: 5px;
  padding-left: 15px;
  border-bottom-style: solid;
  border-bottom-width: thin;
  width: 100%
}