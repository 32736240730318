.kserv-project-admin-panel {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  justify-items: center;
}

.kserv-log-reprocess-grid {
  width: 100%;
  min-width: auto;
  margin: 20px auto auto;
  alignment: center;
}

.kserv-project-admin-page {
  width: 60%;
  justify-content: center;
  align-content: center;
  justify-items: center;
  align-items: center;
  margin: auto;
  display: flex;
}